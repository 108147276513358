export default function popup() {
  const btn = $('.js-show-popup');
  const popup = $('.js-popup');
  const bg = $('.js-bg');
  const btnClose = $('.js-close-popup');
  btn.on('click', function() {
    popup.addClass('is-open');
    bg.addClass('is-open');

  });
  bg.on('click', function() {
    popup.removeClass('is-open');
    bg.removeClass('is-open');
    if ($('html').hasClass('js-item-added')) {
      window.history.replaceState(null, null, window.location.href.split('?')[0]);
    }
  });
  btnClose.on('click', function() {
    popup.removeClass('is-open');
    bg.removeClass('is-open');
    if ($('html').hasClass('js-item-added')) {

      window.history.replaceState(null, null, window.location.href.split('?')[0]);
    }
  });
}
popup();
