import 'owl.carousel';
import  'slick-carousel';
import is_touch_device from '../lib/detectTouch';

export default function sliders() {


  $('.card__popup').removeClass('is-hidden').hide();

  $('.card__popup').click(function(evt) {
    if (!$(evt.target).closest('.js-popup-slider-item').length) {      
      $('html, body').css('overflow', 'auto');
      $('.card__popup').fadeOut(function() {
        $(this).find('.js-popup-slider-item').removeClass('is-active').hide();
        $(this).find('.js-popup-slider-dot').removeClass('is-active');
      });
    }
  });
  $('.card__popup-close').click(function() {
    $('html, body').css('overflow', 'auto');
    $('.card__popup').fadeOut(function() {
      $(this).find('.js-popup-slider-item').removeClass('is-active').hide();
      $(this).find('.js-popup-slider-dot').removeClass('is-active');
    });
  });

  $('.js-hero-slider').on('initialized.owl.carousel', function(evt) {

    const self = $(this);
    initDotsColor();

    function initDotsColor() {

      const $currentSlide = self.find('.owl-item.active');
      const $dots = self.find('.owl-dot');
      if (!$dots.length) {
        setTimeout(initDotsColor, 100);
      }
      const color = $currentSlide.find('.hero-main').data('color');

      $dots.css('border-color', color);
    }
    
   
    
  });


  $('.js-hero-slider').on('translated.owl.carousel', function(evt) {

    const $currentSlide = $(this).find(`.owl-item:eq(${evt.item.index})`);
    const $img = $currentSlide.find('.hero-main__image');
    const $woman = $currentSlide.find('.hero-main__woman');
    const $dots = $(this).find('.owl-dot');
    const color = $currentSlide.find('.hero-main').data('color');

    $dots.css('border-color', color);

    $img.addClass('js-transition-1s').removeClass('is-blurred');
    $woman.removeClass('is-hidden').addClass('js-transition-3s');


    setTimeout(() => {
      $img.removeClass('js-transition-1s');      
    }, 600);

    setTimeout(() => {
      showHeroItem($currentSlide);      
    }, 1500);
    setTimeout(() => {
      $woman.removeClass('js-transition-3s');
    }, 4000);
  });


  const heroSliderOpts = {
    items: 1,
    dotsSpeed: 800,
    dragEndSpeed: 800,
    animateOut: 'fadeOut',
    loop: true
  };


  if (!is_touch_device()) {
    heroSliderOpts.touchDrag = false;
    heroSliderOpts.mouseDrag = false;
  }

  $('.js-hero-slider').owlCarousel(heroSliderOpts);

  function showHeroItem($el) {

    const $$targets = $el.find('.is-hidden');
    let $firstTarget = null;
    if ($$targets.length) {
      $firstTarget = $$targets.first();
      $firstTarget.addClass('js-transition-1s').removeClass('is-hidden');
      // console.log($firstTarget);
    }
   
    setTimeout(() => {
      if ($firstTarget) {
        $firstTarget.removeClass('js-transition-1s');
      }
     
      showHeroItem($el);
    }, 1000);

  }




  $('.js-article-slider:not(.js-article-slider-popup)').owlCarousel({
    items: 1,
    dotsSpeed: 800,
    dragEndSpeed: 800
  });

  $('.js-article-slider-popup').owlCarousel({
    items: 1,
    dotsSpeed: 800,
    dragEndSpeed: 800,
    animateOut: 'fadeOut',
    nav: true,
    navText: [arr, arr]
  });
  let arr = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 83.1 159.2" style="enable-background:new 0 0 83.1 159.2;" xml:space="preserve">
 <path fill="#864bff" d="M5.9,1C5.3,0.4,4.4,0,3.5,0C2.5,0,1.7,0.4,1,1c-1.3,1.3-1.3,3.6,0,4.9l73.7,73.7L1,153.3
   c-1.3,1.3-1.3,3.6,0,4.9c0.6,0.6,1.5,1,2.4,1c0.9,0,1.8-0.4,2.5-1l76.1-76.1c0.7-0.7,1-1.5,1-2.5c0-0.9-0.4-1.8-1-2.4L5.9,1z"/>
</svg>`;

  let arrAlt = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="-10 -10 100 180">
 <path filter="url(#f2)" d="M5.9,1C5.3,0.4,4.4,0,3.5,0C2.5,0,1.7,0.4,1,1c-1.3,1.3-1.3,3.6,0,4.9l73.7,73.7L1,153.3
   c-1.3,1.3-1.3,3.6,0,4.9c0.6,0.6,1.5,1,2.4,1c0.9,0,1.8-0.4,2.5-1l76.1-76.1c0.7-0.7,1-1.5,1-2.5c0-0.9-0.4-1.8-1-2.4L5.9,1z"/>
</svg>`;

  let clickDirection = '';

  
  $('.js-services-slider').on('initialized.owl.carousel', function() {
    setTimeout(() => {
      $(this).find('.owl-prev').click(() => {   
        clickDirection = 'prev';
      });
      $(this).find('.owl-next').click(() => {
        clickDirection = 'next';
      });
    }, 0);
    
  });

  $('.js-services-slider').on('translated.owl.carousel', function(evt) {
    // const $lastSlide = $('.js-services-slider').find('.owl-item').last();
    // const $nextBtn = $('.js-services-slider').find('.owl-next');
    // if ($lastSlide.hasClass('active')) {
    //   $nextBtn.attr('disabled', 'disabled');
    //   $nextBtn.addClass('disabled');
    // } else {
    //   $nextBtn.removeAttr('disabled');
    //   $nextBtn.removeClass('disabled');
    // }
  });

  $('.js-services-slider').on('translate.owl.carousel', function(evt) {
    const index = evt.item.index;
    // console.log('item index', index);
    const $targetElement = $(this).find('.owl-item:nth-child(' + index + ')');
   
      

    // if (window.matchMedia('(min-width: 1024px)').matches) {
    //   if ($targetElement.find('.js-grouped-services').length) {

    //     setTimeout(() => {
    //       if (clickDirection === 'next') {
    //         $(this).trigger('to.owl.carousel', index + 1);
    //       } else if (clickDirection === 'prev') {
    //         $(this).trigger('to.owl.carousel', index - 1);
    //       }
    //     }, 100);
    //   }
    // } else if (window.matchMedia('(min-width: 551px)').matches) {
    //   if ($targetElement.find('.service:not(.service_grouped, .service_big)').length) {
    //     setTimeout(() => {
    //       if (clickDirection === 'next') {
    //         $(this).trigger('to.owl.carousel', index + 2);
    //       } else if (clickDirection === 'prev') {
    //         $(this).trigger('to.owl.carousel', index - 2);
    //       }
    //     }, 100);
    //   }
    // } else {
    //   if ($targetElement.find('.js-grouped-services').length) {
    //     // console.log('condition worked');

    //     setTimeout(() => {
    //       if (clickDirection === 'next') {
    //         $(this).trigger('to.owl.carousel', index + 1);
    //       } else if (clickDirection === 'prev') {
    //         $(this).trigger('to.owl.carousel', index - 1);
    //       }
    //     }, 100);
    //   }
    // }
  });
  $('.js-services-slider').owlCarousel({
    items: 3,
    nav: true,
    navText: [arr, arr],
    autoWidth: true,
    responsive: {
      1023: {
        items: 2,
        autoWidth: true
      }
    }
  });

  $('.js-prefooter-slider').owlCarousel({
    items: 1,
    margin: 0,
    responsive: {
      767:{
        items: 2
      },
      1280:{
        items: 3
      }
    }
  });

  //adjust services slider height
  adjustServicesAboutHeight();
  $(window).resize(adjustServicesAboutHeight);


  function adjustServicesAboutHeight() {
    let $$items = null;
    $('.js-services-slider .service__about').removeAttr('style');
    setTimeout(() => {
      if ($(window).width() > 600) {
        $$items = $('.js-services-slider').find('.service:not(.service_big)');
     
      } else {
        $$items = $('.js-services-slider').find('.service');
      }
      let aboutHeights = [];

      $$items.each((i, el) => {   
        aboutHeights.push($(el).find('.service__about').height());
      });

      let maxHeight = aboutHeights.sort((a, b) => b - a)[0];
      $$items.each((i, el) => {   
        $(el).find('.service__about').height(maxHeight);
      });
    }, 0);
    



  }
 

 
  $(document).click((evt) => {
    const $target = $(evt.target).closest('.js-card-slide');
    if (!$target.length) {
      return;
    }
    $('body, html').css('overflow', 'hidden');
    const activeIndex = $target.closest('.slick-slide').index($('.js-card-big').slick.$slides);
    const $targetPopUp = $('.card__popup');
    $targetPopUp.find('.js-popup-slider-item[data-slide=' + activeIndex + ']').show();
    $targetPopUp.find('.js-popup-slider-dot').removeClass('is-active');
    $targetPopUp.find('.js-popup-slider-dot[data-slide=' + activeIndex + ']').addClass('is-active');
    $targetPopUp.fadeIn();

  });
  
 

  $('.js-card-big').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: `<button class="slider-btn slider-btn_next">${arrAlt}</button>`,
    prevArrow: `<button class="slider-btn slider-btn_prev">${arrAlt}</button>`,
    fade: true,
    asNavFor: '.js-card-nav',
    responsive: [
      {
        breakpoint: 1023,
        settings:{
          slidesToShow: 1
        }}
    ]
  });

  $('.js-card-nav').on('init', function() {
    const slickList = $(this).find('.slick-list');
    const slides = $(this).find('.slick-slide');
    const targetHeight = $('.card').height();


    slides.css('height', targetHeight / 3 - 10);

    $(window).resize(function() {     
      const targetHeight = $('.card').height();
      slides.css('height', targetHeight / 3 - 10);
    });


    slickList.mousedown(function() {     
      $(this).css('cursor', 'grabbing');
      $(this).find('.slick-slide').css('cursor', 'grabbing');
    });

    slickList.mouseup(function() {
      $(this).css('cursor', 'default');
      $(this).find('.slick-slide').css('cursor', 'default');
    });
  });


  $('.js-card-nav').slick({
    slidesToShow: 3,
    sledesToScroll: 1,
    vertical: true,
    focusOnSelect: true,
    draggable: true,
    verticalSwiping: true,
    arrows: false,
    asNavFor: '.js-card-big',
    responsive: [
      // {
      //   breakpoint: 1170,
      //   settings:{
      //     // slidesToShow: 2
      //   }}
    ]
  });
}




sliders();
