export default function accordion() {
  const btn = $('.js-accordion-btn');
  
  $('.js-accordion').each(function() {
  	if (!$(this).hasClass('is-open')) {
  		$(this).find('.js-accordion-content').hide();
  	}
  });


  btn.on('click', function() {
    const acc = $(this).parent('.js-accordion');
    const content = acc.find('.js-accordion-content');
    acc.toggleClass('is-open');
   	if (acc.hasClass('is-open')) {
   		content.slideDown();
   	} else {
   		content.slideUp();
   	}
  });
}
accordion();
