export default function initPopUpSlider() {


  var ts;
  $(document).bind('touchstart', function(e) {

    if ($(e.target).closest('.js-popup-slider-item').length) {
      ts = e.originalEvent.touches[0].clientX;
    }
    
  });

  $(document).bind('touchend', function(e) {
    if ($(e.target).closest('.js-popup-slider-item').length) {
      var te = e.originalEvent.changedTouches[0].clientX;
      const $target = $(e.target).closest('.js-popup-slider-item');
      if(ts > te+5) {
        swipeLeft($target);
      }else if(ts < te-5) {
        swipeRight($target);
      }
    }
    
  });


  function swipeLeft($target) {
    $target.find($('.js-popup-arr[data-arr="right"]')).click();
  }


  function swipeRight($target) {
    $target.find($('.js-popup-arr[data-arr="left"]')).click();
  }

  var mousePoses;

  $(document).mousedown(function(evt) {
    if ($(evt.target).closest('.js-popup-slider-item').length) {

      if ($(evt.target).closest('.js-popup-slider-dot').length) {        
        return;
      }

      mousePoses = evt.clientX;
      // console.log('down', mousePoses);  
    } else {
      mousePoses = null;
    }
  });

  $(document).mouseup(function(evt) {    
    if (mousePoses !== null) {
      var dir = mousePoses > evt.clientX;
      
      const $target = $('.js-popup-slider-item.is-active');

      if ($(evt.target).closest('.slider-dots').length) {
        return;
      }

      if (dir) {
        const $arrow = $($target.find($('.js-popup-arr[data-arr="right"]')));
        if (!$(evt.target).closest('.js-popup-arr[data-arr="left"]').length && !$(evt.target).closest('.js-popup-arr[data-arr="right"]').length) {
          $arrow.click();
        }
      } else {
        const $arrow = $($target.find($('.js-popup-arr[data-arr="left"]')));

        if (!$(evt.target).closest('.js-popup-arr[data-arr="left"]').length && !$(evt.target).closest('.js-popup-arr[data-arr="right"]').length) {
          $arrow.click();
        }
       
      }
 
    }
    
  });

  $('.popup-slider__item-inner img').on('dragstart', (evt) => {
    evt.preventDefault();
  });

  
 
  $('.js-popup-slider').each(function() {
  	const $$slides = $(this).find('.js-popup-slider-item');
  	const $$dots = $(this).find('.js-popup-slider-dot');
  	const $$arrows = $(this).find('.js-popup-arr');

  	$$arrows.click(function() {

  		const slidesCount = $(this).closest('.js-popup-slider').find('.js-popup-slider-item').length;
  		const dir = $(this).data('arr');
  		let activeSlideIndex = 0;
  		let newSlideIndex = 0;

  		$$slides.each((i, el) => {
  			if ($(el).hasClass('is-active')) {
  				activeSlideIndex = i;
  				return false;
  			}
  		});

  		if (dir === 'left') {
  			newSlideIndex = activeSlideIndex - 1;
  			if (newSlideIndex < 0) {
  				newSlideIndex = slidesCount - 1;
  			}

  		} else if (dir === 'right') {
  			newSlideIndex = activeSlideIndex + 1;
  			if (newSlideIndex === slidesCount) {
  				newSlideIndex = 0;
  			}
  		}
  		$$dots.removeClass('is-active');
  		$($$slides[activeSlideIndex]).removeClass('is-active').fadeOut(function() {
  			$$dots.each(function() {
 			if ($(this).data('slide') !== newSlideIndex) {
 				$(this).removeClass('is-active');
 			} else {
 				$(this).addClass('is-active');
 			}
 		});
  			$($$slides[newSlideIndex]).addClass('is-active').fadeIn();
  		});
      
  	});





  	$$slides.hide();
 	
   	$$dots.click(function(evt) {
   		evt.preventDefault();
   		const targetSlideIndex = $(this).data('slide');

      // console.log('targetSlideIndex', targetSlideIndex);
   		$$dots.each(function() {
        // console.log('data-slide', $(this).data('slide'));
   			if ($(this).data('slide') !== targetSlideIndex) {

   				$(this).removeClass('is-active');
   			} else {
   				$(this).addClass('is-active');
   			}
   		});


   		

   		const $activeSlide = $(this).closest('.js-popup-slider').find('.popup-slider__item.is-active');
   		const $targetSlide = $(this).closest('.js-popup-slider').find('.js-popup-slider-item[data-slide="' + targetSlideIndex + '"]');

   		$activeSlide.removeClass('is-active').fadeOut(function() {
   			$targetSlide.addClass('is-active').fadeIn();
   		});

 	});
  });
  
}
