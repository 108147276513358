import {formGrid} from './grid.js';
import { loadPreloader } from './animation.js';
import { removeAllScenes } from './animation.js';
import objectFitImages from 'object-fit-images';
import { throttle } from 'lodash'; 

let isSorting = false;
let isUploading = false;
let AllPostsUploaded = false;

let filterState = {
  countries: [],
  services: [],
  work_types: [],
  colors: [],
  lease: []
};

let pricesTouched = false;

  
let minPriceVal = +$('.js-min-price ').val();
let maxPriceVal = +$('.js-max-price ').val();  
filterState.minPrice = 0;
filterState.maxPrice = 0;


let garmentsBlock = null;

const $garmentsBlock = $('.js-garments-item');

if ($garmentsBlock.length) {
  garmentsBlock = $garmentsBlock[0].cloneNode(true);
}

export default function filters() { 
 
  //search
  $('.js-search-form').submit(function(evt) {
    evt.preventDefault();
    loadPreloader($('.main-preloader.not-main'), true);
    const value = ('' + $(this).find('.js-search-input').val()).trim();
    const $parent = $('.js-categories');
    const sortCat = $parent.attr('data-orderby');
    const sortDir = $parent.attr('data-order');

    if (value) {
      // console.log('search empty');
      $parent.attr('data-post-keyword', value);
    } else {
      if (!$parent.attr('data-post-keyword')) {
        // console.log('search empty and no previous query');
        $(this).closest('.js-search-box').removeClass('is-open');
        $(this).closest('.js-search').removeClass('is-open');
        return;
      } else {
        $parent.attr('data-post-keyword', '');
      }
      // console.log('search empty');
    }

    $(this).closest('.js-search-box').removeClass('is-open');
    $(this).closest('.js-search').removeClass('is-open');
    

    sortPosts(sortCat, sortDir, $parent); 
  });


  //producttags
  $('.js-product-tag').click(function() {
    const $parent = $('.js-categories');
    let selectedTags = $parent.attr('data-tags');

    if (!selectedTags) {
      if ($(this).is(':checked')) {
        $parent.attr('data-tags', $(this).val());
      } 
    } else {
      selectedTags = selectedTags.split('|');
      selectedTags.forEach((el, i) => {
        if (+el === +$(this).val()) {
          selectedTags.splice(i, 1);
        }
      });
      if ($(this).is(':checked')) {
        selectedTags.push($(this).val());
      }
      $parent.attr('data-tags', selectedTags.join('|'));
    }

    if (!$(this).closest('.js-accordion').length) {
      sortPosts('date', 'DESC', $parent, true);
    }
    
  });

  //worktypes;
  $('.js-work-type').click(function() {
    const $parent = $('.js-categories');
    let selectedTags = $parent.attr('data-work-types');

    if (!selectedTags) {
      if ($(this).is(':checked')) {
        $parent.attr('data-work-types', $(this).val());
      } 
    } else {
      selectedTags = selectedTags.split('|');
      selectedTags.forEach((el, i) => {
        if (+el === +$(this).val()) {
          selectedTags.splice(i, 1);
        }
      });
      if ($(this).is(':checked')) {
        selectedTags.push($(this).val());
      }
      $parent.attr('data-work-types', selectedTags.join('|'));
    }

    // sortPosts('date', 'DESC', $parent, true);
  });




  //sortboxes

  $('.js-tab-label').each(function() {
    $(this).click(function() {
      setTimeout(() => {
        const isMobile = $(this).closest('.js-accordion-content').length;
        querySort($(this).find('input:checked'), $(this), isMobile);
      }, 0);
    });
  });

  $('.js-tab-label').each((i, el) => {
    const $inputs = $(el).find('input');
    checkInputs($inputs, $(el));
    $inputs.each(function() {
      $(this).change(() => {
        checkInputs($inputs, $(el));
      });
    });
  });

  const $targetForm = $('.js-filters').find('form');

  if (!$targetForm.length) {
    return;
  }

  $targetForm.submit(function(evt) {
    evt.preventDefault();

    const $parent = $('.js-categories');

    //defining price changes
    if ($('.js-min-price ').length) {
      const initialMinPrice = minPriceVal;
      const initialMaxPrice = maxPriceVal;

      // console.log($targetForm.serialize());
      const actualMinPrice = +$('.js-min-price ').val().split('.').join('').replace(' Р', '');
      const actualMaxPrice = +$('.js-max-price ').val().split('.').join('').replace(' Р', '');

      // console.log('actualMinPrice', actualMinPrice);
      // console.log('actualMaxPrice', actualMaxPrice);

      let pricesSet = false;

      if (pricesTouched) {
        pricesSet = true;
      } else {
        pricesSet = !(actualMinPrice === minPriceVal) || !(actualMaxPrice === maxPriceVal);
        if (pricesSet) {
          pricesTouched = true;
        }
      }
      if (pricesSet) {
        $parent.attr('data-min-price', actualMinPrice);
        $parent.attr('data-max-price', actualMaxPrice);
      }
    }
   


    const selectedServices = [];
    const $services = $(this).find('input[name="cats"]:checked');

    if ($services.length) {
      $services.each(function(i, el) {
        selectedServices.push($(el).val());
      });
      $parent.attr('data-services', selectedServices.join('|'));
    } else {
      $parent.attr('data-services', '');
    }

    const selectedCountries = [];
    const $countries = $(this).find('input[name="countries"]:checked');

    if ($countries.length) {
      $countries.each(function(i, el) {
        selectedCountries.push($(el).val());
      });
      $parent.attr('data-countries', selectedCountries.join('|'));
    } else {
      $parent.attr('data-countries', '');
    }

    const selectedColors = [];
    const $colors = $(this).find('input[name="colors"]:checked');

    if ($colors.length) {
      $colors.each(function(i, el) {
        selectedColors.push($(el).val());
      });
      $parent.attr('data-colors', selectedColors.join('|'));
    } else {
      $parent.attr('data-colors', '');
    }

    const selectedLease = [];
    const $lease = $(this).find('input[name="lease"]:checked');

    if ($lease.length) {
      $lease.each(function(i, el) {
        selectedLease.push($(el).val());
      });
      $parent.attr('data-lease', selectedLease.join('|'));
    } else {
      $parent.attr('data-lease', '');
    }

    $parent.attr('data-post-keyword', '');
    $('.js-search-input').val('');

    sortPosts($parent.data('orderby'), $parent.data('order'), $parent);
  });


  $('.js-filters').find('input').change(function() {
    // console.log('changed', $(this));
    // console.log('is-checked', $(this).is(':checked'));

  });
 

  $('.js-apply-filters').on('click', () => {
    $('.js-filters-bg').trigger('click');
  });
   
  $('.js-clear-filters').on('click', function() {
    
    $targetForm.find('input[type="checkbox"]').prop('checked', false);
    $targetForm.find('input[type="radio"]').prop('checked', false);
    $targetForm.find('.js-min').each(function() {
      $(this).val($(this).data('initial-value'));
    });
    $targetForm.find('.js-max').each(function() {
      $(this).val($(this).data('initial-value'));
    });
    $targetForm.find('.js-range').each(function() {
      const $parent = $(this).closest('.js-accordion-content');
      const min = $parent.find('.js-min').data('initial-value');
      const max = $parent.find('.js-max').data('initial-value');
      $(this)[0].noUiSlider.set([min, max]);
    });
    $parent.attr('data-post-keyword', '');
    $('.js-search-input').val('');
    $targetForm.submit();
  });

  $('.js-show-filters').on('click', () => {
    const $parent = $('.js-filters');
    const $countries =  $parent.find('input[name="countries"]:checked');
    const $services =  $parent.find('input[name="cats"]:checked');
    const $workTypes = $parent.find('input[name="work_type"]:checked');
    const $colors = $parent.find('input[name="colors"]:checked');
    const $lease = $parent.find('input[name="lease"]:checked');


    filterState.countries = [];
    if ($countries.length) {      
      $countries.each(function() {
        filterState.countries.push($(this).val());
      });
    }
    filterState.services = [];
    if ($services.legth) {
      $services.each(function() {
        filterState.services.push($(this).val());
      });
    }
    filterState.work_types = [];
    if ($workTypes.legth) {
      $workTypes.each(function() {
        filterState.work_types.push($(this).val());
      });
    }

    filterState.colors = [];
    if ($colors.legth) {
      $colors.each(function() {
        filterState.colors.push($(this).val());
      });
    }

    filterState.lease = [];
    if ($lease.legth) {
      $lease.each(function() {
        filterState.lease.push($(this).val());
      });
    }


    if ($('.js-min-price ').length) {
      const minPrice = $('.js-min-price ').val();
      const maxPrice = $('.js-max-price ').val();
      filterState.maxPrice = maxPrice;
      filterState.minPrice = minPrice;
    }

    // console.log('filter state', filterState);

    $('.js-filters').addClass('is-open');
    $('body, html').css('overflow', 'hidden');
  });


  $('.js-filters-bg').on('click', function() {
    $(this).parents('.js-filters').removeClass('is-open');
    $('body, html').css('overflow', 'auto');
    $(this).closest('.js-filters').find('form').trigger('submit');
  });

  $('.js-hide-filters').on('click', function() {
    $(this).parents('.js-filters').removeClass('is-open');
    $('body, html').css('overflow', 'auto');


    const $parent = $('.js-filters');
    const $countries =  $parent.find('input[name="countries"]');
    const $services =  $parent.find('input[name="cats"]');
    const $colors = $parent.find('input[name="colors"]');
    const $lease = $parent.find('input[name="lease"]');

    $countries.each(function() {
      if (filterState.countries.indexOf($(this).val()) > -1) {
        $(this).prop('checked', true);
      } else {
        $(this).prop('checked', false);
      }
    });

    $services.each(function() {
      if (filterState.services.indexOf($(this).val()) > -1) {
        $(this).prop('checked', true);
      } else {
        $(this).prop('checked', false);
      }
    });

    $colors.each(function() {
      if (filterState.colors.indexOf($(this).val()) > -1) {
        $(this).prop('checked', true);
      } else {
        $(this).prop('checked', false);
      }
    });

    $lease.each(function() {
      if (filterState.lease.indexOf($(this).val()) > -1) {
        $(this).prop('checked', true);
      } else {
        $(this).prop('checked', false);
      }
    });

    if ($('.js-min-price ').length) {
      const minPrice = $('.js-min-price ');
      const maxPrice = $('.js-max-price ');
      minPrice.val(filterState.minPrice);
      maxPrice.val(filterState.maxPrice);
    }
  });

  const $postsHolder = $('.js-categories');

  if ($(window).scrollTop() + $(window).height() > $postsHolder.offset().top + $postsHolder.height()) {
    $(window).on('scroll', checkForScrollToTop);
  } else {
    $(window).on('scroll', scrollUploadHandler);
  }
}
filters();


function checkForScrollToTop() {
  const $postsHolder = $('.js-categories');
  $(window).off('scroll', checkForScrollToTop);
  if ($(window).scrollTop() + $(window).height() < $postsHolder.offset().top + $postsHolder.height()) {
    // console.log('SCROLLTOP CATCHED');
    $(window).on('scroll', scrollUploadHandler);
    return;
  }
  setTimeout(() => $(window).on('scroll', checkForScrollToTop), 100);
}

function scrollUploadHandler() {
  if (isUploading) {
    return;
  }
  $(window).off('scroll', scrollUploadHandler);
  
  const $target = $('.js-categories');
  const condition = $(window).scrollTop() + $(window).height() >= $target.offset().top + $target.outerHeight();

  if (condition) {
    // console.log('condition');
    isUploading = true;
    $(window).off('scroll', scrollUploadHandler);
    loadMorePosts($target);
    return;
  }
  
  setTimeout(() => $(window).on('scroll', scrollUploadHandler),100);  
}


function checkInputs($inputs, $parent) {
  let checkedInputs = 0;
  $inputs.each(function() {
    if ($(this).closest('.is-checked.is-checked-asc').length) {
      return false;
    }
    if ($(this).is(':checked')) {
      checkedInputs++;

      if ($(this).closest('.is-asc').length) {       
        $parent.addClass('is-checked is-checked-asc');
        $parent.removeClass('is-checked-desc');
      } else if ($(this).closest('.is-desc').length) {
        $parent.addClass('is-checked is-checked-desc');
        $parent.removeClass('is-checked-asc');
      }
    }
  });

  if (!checkedInputs) {
    $parent.removeClass('is-checked is-checked-asc is-checked-desc');
    $parent.find('input').each((i, el) => {
      $(el).prop('checked', false);
    });
  } else {
    $parent.closest('.js-tab-labels').find('.js-tab-label').each(function() {
      if ($(this)[0] === $parent[0]) {
        return;
      }
      $(this).removeClass('is-checked is-checked-asc is-checked-desc');
      $(this).find('input').each(function() {
        $(this).prop('checked', false);
      });
    });
  }

  
}





function loadMorePosts($parent) {
  const url = $parent.data('url');
  const taxonomy = $parent.data('page');
  const category = $parent.attr('data-category');
  const post_type = $parent.data('type');
  let countries = $parent.attr('data-countries').split('|');
  

  if (countries.length === 1 && countries[0] === '') {
    countries = '';
  }
  let services = $parent.attr('data-services').split('|');
  if (services.length === 1 && services[0] === '') {
    services = '';
  }

  let tags = $parent.attr('data-tags');
  if (tags) {
    tags = tags.split('|');
    if (tags.length === 1 && tags[0] === '') {
      tags = '';
    }
  }

  let workTypes = $parent.attr('data-work-types');
  if (workTypes) {
    workTypes = workTypes.split('|');
    if (workTypes.length === 1 && workTypes[0] === '') {
      workTypes = '';
    }
  }

  let colors =  $parent.attr('data-colors');
  if (colors) {
    colors = colors.split('|');
    if (colors.length === 1 && colors[0] === '') {
      colors = '';
    }
  }

  let lease =  $parent.attr('data-lease');
  if (lease) {
    lease = lease.split('|');
    if (lease.length === 1 && lease[0] === '') {
      lease = '';
    }
  }



  let post_keyword = $parent.attr('data-post-keyword') ? $parent.attr('data-post-keyword') : '';


  //checking for reserved posts
  const reservedPosts = [];

  const $$reservedPosts = $('.js-grid-item-reserved');

  $$reservedPosts.each(function() {
    reservedPosts.push($(this).hasClass('js-grid-item-big') || $(this).data('class') === 'big');
  });

  const prodsMeta = {
    post_type: post_type,
    order: $parent.attr('data-order'),
    orderby: $parent.attr('data-orderby'),
    taxonomy: taxonomy,
    terms: category,
    show: $parent.data('show'),
    reservedPosts: reservedPosts,
    page_url: $parent.data('page-url'),
    numberposts: (+$parent.data('rows-per-page')) * (+$parent.data('items-per-row')),
    offset: true,
    countries: countries,
    services: services,
    colors: colors,
    lease: lease,
    post_keyword: post_keyword,
    rows_per_page: $parent.data('rows-per-page'),
    items_per_row: $parent.data('items-per-row'),
    garments: !!$parent.data('garments')
  };

  if (tags && tags.length) {
    prodsMeta.product_tags = tags;
  } else {
    prodsMeta.product_tags = '';
  }

  if (workTypes && workTypes.length) {
    prodsMeta.work_types = workTypes;
  } else {
    prodsMeta.work_types = '';
  }

  if ($('.js-min-price ').length) {
    let priceMin = +$parent.attr('data-min-price');
    let priceMax = +$parent.attr('data-max-price');
    prodsMeta.min_price = priceMin;
    prodsMeta.max_price = priceMax;
  } else {
    prodsMeta.min_price = '';
    prodsMeta.max_price = '';
  }



  $('.preloader-bottom').addClass('is-active');
  const data = {
    action: 'ethno_load_more_prods',
    prodsMeta
  };

  // console.log('url', url),
  // console.log('sent', data);

  $.ajax({
    type: 'GET',
    url,
    data,
    success: function(data) {
      const received_data = JSON.parse(data);
      // console.log('received', received_data);

      //revealing reserved posts

     

      if (received_data.error) {
        // console.log('some error');
        return;
      }
      // console.log('received posts', received_data.posts);
      // if (!received_data.posts.length && !$$reservedPosts.length) {
      //   $('.js-services-not-found').show();
      // } else {
      //   $('.js-services-not-found').hide();
      // }

      const reserveLimit = prodsMeta.numberposts;
      let reservedCount = 0;

      if (received_data.posts.length) {
        $$reservedPosts.each((i, el) => {
          if ($(el).hasClass('js-grid-item-big')) {
            reservedCount += 2;
          } else {
            reservedCount++;
          }
        
          $(el).removeClass('js-grid-item-reserved');
          if (reservedCount >= reserveLimit) {
            return false;
          }
        });
      } else {
        $$reservedPosts.removeClass('js-grid-item-reserved');
      }
     

      received_data.posts.forEach((el, i) => {
        appendPost(el, $parent);
      });

      
      $parent.data('show', +$parent.data('show') + 1);
      
     
      $('.preloader-bottom').removeClass('is-active');
      objectFitImages($parent.find('img'));
      $('.js-grid').data('row-normalized', false);
      formGrid($('.js-grid'));
      if (received_data.more_btn_hidden && !received_data.reserved_posts) {
        // console.log('NO MORE POSTS LEFT');
        AllPostsUploaded = true;
      } else {

        setTimeout(() => {
          isUploading = false;
          $(window).on('scroll', scrollUploadHandler);
        }, 2000);
      }
     
    },
    error: function(errorThrown) {
      // console.error(errorThrown);
    }
  });
}


function appendPost(data, $parent) {
  // console.log('appending', data);
  let htmlString = '';
  if ($parent.data('type') === 'product') {
    htmlString = `
      <div data-id="${ data.id }" class="items-list__item js-grid-item ${ data.big ? 'js-grid-item-big' : '' } ${data.reserved ? 'js-grid-item-reserved' : ''}">
        <div class="service ${ data.big ? 'service_big' : '' }">
            <div class="service__inner">
                <div class="service__img">
                    ${data.img}
                    ${data.img_big}
                </div>
                <a href="${data.link}" class="service__url"></a>
               <div class="service__about">
                  <a href="${data.link}" class="service__about-link"></a>
                    <div class="service__categ">`;
    data.terms.forEach((el, i) => {
      htmlString += `
                          <span data-id="${el.id}">${el.name}</span>
                        `;
    });
    htmlString += `
                    </div>
                    <h3 class="service__title"><span>${ data.title }</span></h3>
                    <div class="service__bottom">
                        <a href="${data.link}" class="service__price">${data.price} р</a>
                        <a href="${data.add_to_cart}" class="service__btn">ДОБАВИТЬ В ЗАКАЗ</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  `;
  } else {
    htmlString = `
      <a href="${data.link}" data-id="${data.id}" class="items-list__item
        js-grid-item${ data.big ? ' js-grid-item-big' : '' } ${data.reserved ? 'js-grid-item-reserved' : ''}">
            <div class="p-preview">
               `;
    data.tags.forEach((el, i) => {
      htmlString += `                        
                          <div class="p-preview__tag tag">${el.name}</div>
                `;
    });
    htmlString += `
                <div class="p-preview__img">
                    ${data.img}
                    ${data.img_big}
                </div>
                <div class="p-preview__text">
                    <h3 class="p-preview__title">${data.title}</h3>
                    ${data.desc}
                    <p class="p-preview__date">${data.date}</p>
                </div>
            </div>
        </a>
    `;
  } 

  $parent.find('.js-grid').append(htmlString);
  $(window).resize();
}


function querySort($input, $label, isMobile) {
  if (isSorting) {
    return;
  }

  let sortCat = '';
  let sortDir = '';

  if (!$input.length) {
    sortCat = 'date';
    sortDir = 'desc';
  } else {
    sortCat = $input.val().split('|')[0]; 
    sortDir = $input.val().split('|')[1];
  }

  isSorting = true;

  
  switch (sortCat) {
    case 'popularity':
      break;
    case 'alphabet':
      sortCat = 'title';
      break;
    case 'price':
      break;
    default:
      sortCat = 'date';
  }

  switch (sortDir) {
    case 'asc':
      sortDir = 'ASC';
      if (sortCat === 'title') {
        sortDir = 'DESC';
      } 
      break;
    case 'desc':
      sortDir = 'DESC';
      if (sortCat === 'title') {
        sortDir = 'ASC';
      } 
      break;
    default:
      sortDir = 'DESC';
  }

  const $parent = $label.closest('.js-categories');

  $parent.attr('data-order', sortDir);
  $parent.attr('data-orderby', sortCat);

  if (!isMobile) {
    sortPosts(sortCat, sortDir, $parent);
  }

  setTimeout(() => {
    isSorting = false;
  }, 100);



  // console.log('Active input', $input);
}


function sortPosts(sortCat, sortDir, $parent, normalize) {
  $parent.find('.js-grid').removeAttr('style').empty();
  removeAllScenes();

  $('.preloader-top').addClass('is-active');
  const url = $parent.data('url');
  const taxonomy = $parent.data('page');
  const post_type = $parent.data('type');

  // console.log($parent);
  const category = $parent.attr('data-category');

  let countries = $parent.attr('data-countries').split('|');
  if (countries.length === 1 && countries[0] === '') {
    countries = '';
  }

  let services = $parent.attr('data-services').split('|');
  if (services.length === 1 && services[0] === '') {
    services = '';
  }

  let tags = $parent.attr('data-tags');
  if (tags) {
    tags = tags.split('|');
    if (tags.length === 1 && tags[0] === '') {
      tags = '';
    }
  }


  let workTypes = $parent.attr('data-work-types');
  if (workTypes) {
    workTypes = workTypes.split('|');
    if (workTypes.length === 1 && workTypes[0] === '') {
      workTypes = '';
    }
  }

  let colors = $parent.attr('data-colors');
  if (colors) {
    colors = colors.split('|');
    if (colors.length === 1 && colors[0] === '') {
      colors = '';
    }
  }

  let lease = $parent.attr('data-lease');
  if (lease) {
    lease = lease.split('|');
    if (lease.length === 1 && lease[0] === '') {
      lease = '';
    }
  }


  let post_keyword = $parent.attr('data-post-keyword') ? $parent.attr('data-post-keyword') : '';

  const prodsMeta = {
    reservedPosts: [],
    post_type: post_type,
    order: sortDir,
    orderby: sortCat,
    taxonomy: taxonomy,
    terms: category,
    show: +$parent.data('show'),
    page_url: $parent.data('page-url'),
    numberposts: (+$parent.data('rows-per-page')) * (+$parent.data('items-per-row')) * (+$parent.data('show')),
    countries: countries,
    services: services,
    colors: colors,
    lease: lease,
    post_keyword: post_keyword,
    rows_per_page: $parent.data('rows-per-page'),
    items_per_row: $parent.data('items-per-row'),
    garments: !!$parent.data('garments')
  };
  if (tags && tags.length) {
    prodsMeta.product_tags = tags;
  } else {
    prodsMeta.product_tags = '';
  }

  if (workTypes && workTypes.length) {
    prodsMeta.work_types = workTypes;
  } else {
    prodsMeta.work_types = '';
  }

  if ($('.js-min-price ').length) {
    let priceMin = +$parent.attr('data-min-price');
    let priceMax = +$parent.attr('data-max-price');
    prodsMeta.min_price = priceMin;
    prodsMeta.max_price = priceMax;
  } else {
    prodsMeta.min_price = '';
    prodsMeta.max_price = '';
  }

   

  // console.log('data(sort)', data);


  const data = {
    action: 'ethno_sort_prods',
    prodsMeta
  };

  // console.log('sent (sort)', data);
  isUploading = true;
  $.ajax({
    type: 'GET',
    url,
    data,
    success: function(data) {
      
      const received_data = JSON.parse(data);
      if (received_data.error) {
        return;
      }

      // console.log('received (post)', received_data);

      $parent.attr('data-order', sortDir);
      $parent.attr('data-orderby', sortCat);
      $parent.find('.js-grid').empty();
      removeAllScenes();
      $('.preloader-top').removeClass('is-active');

      if (!received_data.posts.length) {
        $('.js-services-not-found').show();
      } else {
        $('.js-services-not-found').hide();
        // console.log(filterState);
        if (garmentsBlock && garmentsBlockAllowed($parent)) {
          $parent.find('.js-grid').append(garmentsBlock.cloneNode(true));
        }       
      }
     
      received_data.posts.forEach((el, i) => {
        appendPost(el, $parent);
      });      
      objectFitImages($parent.find('img'));
      $('.js-grid').data('first-row-adjusted', false);
      $('.js-grid').data('row-normalized', false);
      formGrid($('.js-grid')); 
      $('html, body').removeAttr('style');
      if (AllPostsUploaded) {
        $('.js-grid-item-reserved').removeClass('js-grid-item-reserved');
      }
      isUploading = false;
    },
    error: function(errorThrown) {
      // console.error(errorThrown);
    }
  });
}


function garmentsBlockAllowed($parent) {
  const condition_1 = $parent.attr('data-orderby') === 'date';
  const condition_2 = !$parent.attr('data-services');
  const condition_3 = !$parent.attr('post-keyword');
 

  return condition_1 && condition_2 && condition_3;
}
