import { EMLINK } from 'constants';

export default function count() {
  let counters = $('.js-count');
  // if(counters) {
  //   counters.each((index, el) => {
  //   //   console.log(el, index);
  //     const plusBtn = $(el).find('.js-count-plus');
  //     const minusBtn = $(el).find('.js-count-minus');
  //     let count = $(el).find('.js-count-value');
  //     let numb = $(el).data('count');

  //     plusBtn.on('click', () => {
  //       let countVal = count.html();
  //       count.html(+countVal+1);
  //       updateSumm(numb, +countVal+1);
  //     });

  //     minusBtn.on('click', () => {
  //       let countVal = count.html();
  //       if(countVal > 1) {
  //         count.html(+countVal - 1);
  //         updateSumm(numb, +countVal-1);
  //       }
  //     });

  //   });
  // }
  removeItems();
}
function updateSumm(id, count) {
  // let price = $(`[data-row="${id}"]`).find('[data-price]').data('price');
  // let summ = $(`[data-basket-summ="${id}"]`);
  // summ.html(price*count+ 'P');
  // getTotal();

}


function getTotal() {
  // let sum = 0;
  
  // let summItems = $('[data-basket-summ]');
  // summItems.each((i,element) => {

  //   let val = + $(element).html().slice(0,-1);
  //   sum = sum + val;
  //   $('.js-basket-total').html(sum);
  // });
  
}
function removeItems() {
    
 
    
  $('.js-remove-basket-row').on('click',function() {
    // const numb = $(this).data('basket-remove');
    // $(`[data-row="${numb}"]`).each((i,el) => {
    //   $(el).remove();
    // });
    // getTotal();
  });


}
count();
