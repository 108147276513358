import throttle from 'lodash.throttle';

export default function adjustRateTitleHeight() {
  $(function() {
    adjustRateTitles();
    $(window).resize(throttle(adjustRateTitles, 100));
  });
  


  function adjustRateTitles() {
  	$('.js-rate-title').height('auto');
    let maxHeight = 0;
    $('.js-rate-title').each(function() {
      if ($(this).height() > maxHeight) {
        maxHeight = $(this).height();
      }
    });
    $('.js-rate-title').height(maxHeight);
  }
	
}
