import throttle from 'lodash.throttle';

export default function fixedHeader() {
  const header = $('.js-fixed-header');
  var scrollPos = 0;
  var headerOpen = false;
  $(window).on('scroll', throttle(checkHeader, 100));


  function checkHeader() {
    var st = $(window).scrollTop();

    if($(this).scrollTop()>800 && st < scrollPos && $(window).width()>480) {
      if (!headerOpen) {
        headerOpen = true;
        header.addClass('is-fixed').hide().slideDown();
      }
    } 
    else if($(this).scrollTop()>500 && $(window).width() <= 480 ) {
      if (!headerOpen) {
        headerOpen = true;
        header.addClass('is-fixed').hide().slideDown();
      }
    }
    else {
      if (headerOpen) {
        headerOpen = false;
        header.slideUp(() => {
          header.removeClass('is-fixed').show();
        });
      }
      
    }
    scrollPos = st;
  }
}




fixedHeader();
