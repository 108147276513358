export default function mobMenu() {
  let btn = $('.js-btn-burger');
  let parent = btn.parents('.js-menu-wrap');
  let menu = parent.find('.js-mob-menu');
  let header = $('.js-fixed-header');

  btn.on('click', () => {
    btn.toggleClass('is-open');

    if (btn.hasClass('is-open')) {
      menu.addClass('is-open');
      header.addClass('menu-open');
      // console.log('worked if');
    } else {
      menu.removeClass('is-open');
      header.removeClass('menu-open');
      // console.log('worked else');
    }
   

    if (header.hasClass('menu-open')) {
      $('body, html').css('overflow', 'hidden');
    } else {
      $('body, html').css('overflow', 'auto');
    }   

  });
  $('body').on('click',(e) => {
    if($(e.target).closest('.js-btn-burger').length || $(e.target).closest('.js-mob-menu').length) {
      return;
    } else if ($('.card__popup').length && !$('.card__popup:hidden').length) {

      return;
    } else{
      $('body, html').css('overflow', 'auto');
      btn.removeClass('is-open');
      menu.removeClass('is-open');
      header.removeClass('menu-open');
    }
  });

  $(window).resize(checkHeader);

  function checkHeader() {
    if ($(window).width() > 1023) {
      btn.removeClass('is-open');
      menu.removeClass('is-open');     
      header.removeClass('menu-open');
      $('body, html').css('overflow', 'auto');
    }
  }
}
mobMenu();

