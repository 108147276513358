import debounce from 'lodash.debounce';

export default function adjustCardTextHeight() {
  $(function() {
    $('.scrollbar-inner.js-adjust-size-item').each(function() {
      adjustBlockHeight($(this));
      const self = $(this);
      $(window).resize(() => {
        adjustBlockHeight(self);
      });

      function adjustBlockHeight($el) {
        if (window.matchMedia('(max-width: 1023px)').matches) {
          $el.css('height', 'auto');
          $el.data('resizing', false);
         
          return;
        }
        const $anchor = $el.closest('.js-adjust-size-anchor');
        const $tags = $anchor.find('.card__tags');
        const $title = $anchor.find('.card__title');
        const blockHeight = $anchor.height();
        const tagsHeight = $tags.height() + parseInt($tags.css('margin-bottom'));
        const titleHeight = $title.height() + parseInt($title.css('margin-bottom'));

        $el.height(blockHeight - tagsHeight - titleHeight);

      }
    
    });
   
  });
}
