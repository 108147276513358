export default function steps() {
  
  let btn = $('.js-step-btn');
  btn.on('click', function() {
    let step = $(this).parents('.js-step');
    let stage = $('.js-stage');

    let numb = step.data('step');
    $(`[data-stage="${numb+1}"]`).addClass('is-done');
    step.removeClass('is-visible');
    $(`[data-step="${numb+1}"]`).addClass('is-visible');
  });
}
steps();
