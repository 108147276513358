// import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';
import {setTweenForBlockItem, removeAllScenes} from './animation.js';

export default function initGrids() {
  
  $('.js-grid').each(function() {
    formGrid($(this));
    const $self = $(this);
    $(window).resize(debounce(function() {
      formGrid($self, false);
    }, 200));   
  });  
}


$(function() {
  initGrids();
});



export function formGrid($el) {
  if ($el.hasClass('is-resizing')) {
    return;
  }

  const $garments = $el.find('.js-garments-item');
  if ($garments.length) {
    if (!$el.data('first-row-adjusted')) {
      adjustFirstRow($el, $garments);
    }
    
  }
  


  $el.addClass('is-resizing');

  if (window.matchMedia('(max-width: 1023px)').matches) {
    prettifyGrid($el);

  } else {
    restoreDesctopGrid($el);
  }

  if (window.matchMedia('(max-width: 1023px)').matches && window.matchMedia('(min-width: 601px)').matches) {
    if ($el.hasClass('js-grid-4')) {
      adjustAboutHeight($el);
    }
  } else {
    $el.find('.js-grid-item:not(.js-grid-item-big) .service__about').height('auto');
  }
   


  if (window.matchMedia('(max-width: 600px)').matches) {
    $el.find('.js-grid-item').addClass('js-mobile-image');
  } else {
    $el.find('.js-grid-item').removeClass('js-mobile-image');
  }
  
  setTimeout(() => {
    $el.find('.js-grid-item:not(.js-grid-item-reserved)').each((i, el) => {
      if (!$(el).hasClass('js-tween-set')) {
        $(el).addClass('js-tween-set');
        setTweenForBlockItem($(el));
      }
    });
    $el.removeClass('is-resizing');
  }, 0);
}


function prettifyGrid($grid) {
  if ($grid.data('row-normalized')) {
    return;
  }

  const elemsPerRow = $grid.hasClass('js-grid-4') ? 4 : 3;

  const $$items = $grid.find('.js-grid-item:not(.js-grid-item-reserved)');
  const length = $$items.length;

  let rowItems = 0;


  $$items.each((i, el) => {
    if ($(el).hasClass('js-grid-item-big')) {
      rowItems += 2;
    } else {
      rowItems += 1;
    }

    if (rowItems === 1 && i < length - 1) {
      // console.log('row collecting elems');
    } else if (rowItems === 2) {
      // console.log('row is ok');
      rowItems = 0;
    } else if (rowItems > 2) {
      // console.log('to much elems');
      $(el).data('class', 'big');
      $(el).removeClass('js-grid-item-big');
      $(el).find('.service').removeClass('service_big');
      rowItems = 0;
    } else if (rowItems === 1 && i === length -1) {
      // console.log('last element is too small');
      $(el).data('class', 'small');
      $(el).addClass('js-grid-item-big');
      $(el).find('.service').addClass('service_big');
      $(el).find('.service .service__about').removeAttr('style');  
    }
  });

  

  $grid.data('row-normalized', true);
}

function adjustAboutHeight($grid) {
  const $$items = $grid.find('.js-grid-item:not(.js-grid-item-reserved)');
  let currentTop = 0;
  let currentBatch = [];
  $$items.each((i, el) => {     
    if (currentTop !== $(el).offset().top) {
     
      if (currentBatch.length) {
        let aboutHeights = [];
        currentBatch.forEach($el => {
          aboutHeights.push($el.find('.service__about').height());
        });
        if (aboutHeights[0] > aboutHeights[1]) {
          currentBatch[1].find('.service__about').height(aboutHeights[0]);
        } else {
          currentBatch[0].find('.service__about').height(aboutHeights[1]);
        }
        currentBatch = [];        
      }
      if ($(el).hasClass('js-grid-item-big')) {
        return;
      }
      currentTop = $(el).offset().top;
    } 
    currentBatch.push($(el));
  });
}


function restoreDesctopGrid($grid) {
  const $$items = $grid.find('.js-grid-item:not(.js-grid-item-reserved)');

  $$items.each((i, el) => {
    const givenClass = $(el).data('class');

    if (!givenClass) {
      return;
    }

    if (givenClass === 'big') {
      $(el).addClass('js-grid-item-big'); 
      $(el).data('class', false);  
      $(el).find('.service').addClass('service_big'); 
      $(el).find('.service .service__about').removeAttr('style');  
    } else {
      $(el).removeClass('js-grid-item-big');
      $(el).data('class', false);
      $(el).find('.service').removeClass('service_big');
    }

  });

  $grid.data('row-normalized', false);
}


function adjustFirstRow($grid, $garments) {

  const $$elems = $grid.find('.js-grid-item:not(.js-garments-item)');

  let smallItems = 0;
  let bigItems = 0;

  let numsBig = [];
  let numsSmall = [];

  $$elems.each((i, el) => {
    if ($(el).hasClass('js-grid-item-big')) {
      bigItems++;
    } else {
      smallItems++;
    }

    if (bigItems === 2 && smallItems === 0) {
      // console.log('worked first');
      numsBig = [];
      numsSmall = [0, 1];
      return false;
    } 

    if (smallItems === 2 && bigItems === 1) {
      // console.log('worked second');
      numsBig = [0, 1, 2];
      numsSmall = [];
      return false;
    }


    if (smallItems === 4 && bigItems === 0) {
      // console.log('worked third');
      numsBig = [2, 3];
      numsSmall = [0, 1];
      return false;
    }
  });


  numsBig.forEach(num => {
    if (!$$elems[num]) {
      return;
    }
    $($$elems[num]).addClass('js-grid-item-big'); 
    $($$elems[num]).find('.service').addClass('service_big'); 
  });

  numsSmall.forEach(num => {
    if (!$$elems[num]) {
      return;
    }
    $($$elems[num]).removeClass('js-grid-item-big'); 
    $($$elems[num]).find('.service').removeClass('service_big'); 
  });
  $garments.addClass('is-visible');
  $grid.data('first-row-adjusted', true);
   
}

