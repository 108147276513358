import { TimelineMax, TweenMax, Linear } from 'gsap';
import ScrollMagic from 'scrollmagic';
import 'animation.gsap';
import 'debug.addIndicators';

export const scrollController = new ScrollMagic.Controller(
  {globalSceneOptions: {triggerHook: 'onEnter'}}
);


let animationsSet = false;

let sceneStore = [];


function isInWindow($el) {
  const windowTop = $(window).scrollTop() + $(window).height();
  const elTop = $el.offset().top;
  return windowTop > elTop;
}

export function loadPreloader($preloader, slow) {
  const $pathLine = $preloader.find('.main-preloader__svg-path');
  const $numbers = $preloader.find('.main-preloader__numbers');
  const $nums = $preloader.find('.main-preloader__num');
  $('body, html').css('overflow', 'hidden');
  const updTimes = slow ? 17 : 7;
  const speed = slow ? 2.5 : 1;
  let progress = 0;




  $preloader.fadeIn(200, function() {
    const tl = new TimelineMax();

    tl
      .to($numbers, 1, {opacity: 1, ease: Power2.easeOut, onComplete:() => {

        updateProgress();

      }})
      .to($pathLine, speed, {strokeDashoffset: '0', ease:Linear.easeNone})
      .to([$pathLine, $numbers], 0.2, {opacity: 0, ease: Power2.easeOut, onComplete:() => {
        $('body, html').css('overflow', 'auto');
        $nums.html('0');
        $preloader.fadeOut(() => {
          if (!animationsSet) {
            animation();
            animationsSet = true;
          }
         
        });
      }});
  });


  function updateProgress() {

    progress += Math.ceil(100 / updTimes);

    if (progress > 100) {
      progress = 0;
      $nums.html('100');
      return;
    }
    
    $nums.html(progress);
    if (progress <= 100) {
      setTimeout(updateProgress, speed * 1000 / updTimes);
    }
  }
}


const $preloader = $('.main-preloader');

if ($preloader.length) {
  const slow = $('body').hasClass('home');
  loadPreloader($('.main-preloader:not(.not-main)'), slow);
} else {
  animation();
}







export default function animation() {

  const $slider = $('.hero-slider-wrapper');

  if ($slider.length) {
    const $currentSlide =  $slider.find('.owl-item.active');
    const $img = $currentSlide.find('.hero-main__image');
    const $woman = $currentSlide.find('.hero-main__woman');

    $img.addClass('js-transition-1s').removeClass('is-blurred');
    $woman.removeClass('is-hidden').addClass('js-transition-3s');

    setTimeout(() => {
      $img.removeClass('js-transition-1s');      
    }, 600);

    setTimeout(() => {
      showHeroItem($currentSlide);      
    }, 1000);
    setTimeout(() => {
      $woman.removeClass('js-transition-3s');
    }, 4000);
  }

  function showHeroItem($el) {

    const $$targets = $el.find('.is-hidden');
    let $firstTarget = null;
    if ($$targets.length) {
      $firstTarget = $$targets.first();
      $firstTarget.addClass('js-transition-1s').removeClass('is-hidden');
      // console.log($firstTarget);
    }
   
    setTimeout(() => {
      if ($firstTarget) {
        $firstTarget.removeClass('js-transition-1s');
      }
     
      showHeroItem($el);
    }, 1000);

  }
 
   

  // const $$blockItems = $('.js-grid-item');

  const $$texts = $('.article__inner p, blockquote, .article__slider, .img-center, .article__video, .js-text');
  

  $$texts.each((i, el) => {

    if (isInWindow($(el))) {
      // console.log('in window works');
      // console.log(el);
      return;
    }

  	const tween = TweenMax.fromTo(el, 1, {y: 150, opacity: 0}, {y:0, opacity: 1, ease: Power2.easeOut});

  	const scene = new ScrollMagic.Scene({
      triggerElement: el,
      reverse: false
    })
      .setTween(tween)
      //.addIndicators({name: 'text-animation'})
      .addTo(scrollController);

  });

  const $$staggers = $('.js-stagger-scale');
  initStaggerAnimation($$staggers);


  const $$lineSvgs = $('.js-draw-svg');

  $$lineSvgs.each((i, el) => {
  	const scene = new ScrollMagic.Scene({
      triggerElement: el,
      reverse: false
    })
      .on('enter', () => {
      	$(el).addClass('is-drawn');
      })
      //.addIndicators({name: 'svg'})
      .addTo(scrollController);
  });

  const $$imgs = $('.article .img-left, .article .img-right');

  $$imgs.each((i,el) => {

    const x = $(el).hasClass('img-left') ? -250 : 250;

  	const tween = TweenMax.fromTo(el, 1, {x: x, opacity: 0}, {x:0, opacity: 1, ease: Power2.easeOut});

  	const scene = new ScrollMagic.Scene({
      triggerElement: el,
      reverse: false
    })
      .setTween(tween)
      //.addIndicators({name: 'img'})
      .addTo(scrollController);
  });


  const $$staggersLeft = $('.js-stagger-from-left');

  $$staggersLeft.each((i, el) => {
  	const children = $(el).children().get();
  	const tween = TweenMax.staggerFrom(children, 1, {opacity: 0, x: -200}, 0.2);
  	const scene = new ScrollMagic.Scene({
      triggerElement: el,
      reverse: false
    })
      .setTween(tween)
      //.addIndicators({name: 'stagger-animation'})
      .addTo(scrollController);


  });
}



export function initStaggerAnimation($$elems) {
  $$elems.each((i, el) => {
  	const children = $(el).children().get();
  	const tween = TweenMax.staggerFrom(children, 1, {scale: 0}, 0.1);

  	const scene = new ScrollMagic.Scene({
      triggerElement: el,
      reverse: false
    })
      .setTween(tween)
      //.addIndicators({name: 'stagger-animation'})
      .addTo(scrollController);

  });
}


export function setTweenForBlockItem(el) {


  sceneStore = sceneStore.filter(v => {
    if (v.el[0] === el[0]) {      
      v.scene.destroy();
      return false;
    }
    return true;

  });


  
  const tween = TweenMax.fromTo(el, 1, {y: 250, opacity: 0}, {y:0, opacity: 1, ease: Power2.easeOut});

  const scene = new ScrollMagic.Scene({
    triggerElement: el[0],
    reverse: false
  })
    .setTween(tween)
    .addTo(scrollController);

  sceneStore.push({
    el,
    scene
  });  
}


export function removeAllScenes() {
  sceneStore.forEach(v => {
    v.scene.destroy();

  });
  sceneStore = [];
}
