import sayHello from './lib/sayHello.js';
import is_touch_device from './lib/detectTouch.js';
import components from './components';
import msieversion from './lib/detectIE.js';
import 'jquery.scrollbar';


import inputMask from './components/inputMask';
inputMask();

import objectFitImages from 'object-fit-images';

import svg4everybody from 'svg4everybody';
import stream from './components/stream.js';
import mobMenu from './components/mobMenu.js';
import count from './components/counter.js';
import step from './components/steps';
import searchTogler from './components/searchToggler.js';
import accordion from './components/accordion.js';
import formDate from './components/formDate.js';
import animation  from './components/animation.js';
import popup  from './components/popup.js';
import range from './components/rangeSlider.js';
import initCustomScroll from './components/customScroll.js';
import adjustCardTextHeight from  './components/adjustCardTextHeight';
import adjustRateTitleHeight from './components/adjustRateTitleHeight';
import initArticleCircles from './components/articleCircles';
import initPopUpSlider from './components/_popupSlider';
import filters from './components/filters.js';
import throttle from 'lodash.throttle';


$(function() {
  objectFitImages($('img'));
  initArticleCircles();
  initPopUpSlider();



  const $hero = $('.hero-slider-wrapper');

  $(window).resize(throttle(function() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, 100));
  $(window).resize();


  const $cardLeft = $('.card__left');

  if ($cardLeft.length) {
    adjustCardLeftHeight();
    $(window).resize(throttle(adjustCardLeftHeight, 100));
  }

  function adjustCardLeftHeight() {
    // console.log('worked');
    if ($(window).width() > 1023) {
      $cardLeft.removeAttr('style');
      return;
    } 
    const headerHeight = $('.header').outerHeight();
    const titleHeight = $('.card__title').outerHeight();
    // console.log(headerHeight);
    // console.log(titleHeight);
    $cardLeft.height($(window).height() - headerHeight - titleHeight);
  }

});

if($('.js-player').length) {
  stream();
}
objectFitImages();

// svg4everybody();
if(msieversion()) {
  $('body').addClass('is-ie');
}
let imgs = document.querySelectorAll('img');


sayHello();


if(!is_touch_device()) {
  $('body').addClass('no-touch');
}
// console.log('touch worked');
initFormPopUp();
$('.js-form-contact').each(function() {
  initCF7Form($(this));
});

$('input, textarea').blur(function() {
  if ($(this).closest('.form-elem').hasClass('js-select-input')) {
    return;
  }
  if ($(this).val()) {
    $(this).closest('.form-elem').addClass('input-not-empty');
  } else {
    $(this).closest('.form-elem').removeClass('input-not-empty');
  }
});

$('.js-select-input').click(function() {
  $(this).toggleClass('is-active');
});

$(document).click(function(evt) {
  if (!$(evt.target).closest('.js-select-input').length) {
    $('.js-select-input').removeClass('is-active');
  }

});
$('.js-select-input ul span').click(function() {
  $(this).closest('.js-select-input').find('input').val($(this).parent().data('value'));
  $(this).closest('.js-select-input').find('input + span').text($(this).text());
});

initCustomScroll();


function initCF7Form($el) {
  const $$elems = $el.find('input, textarea');
  const $form = $el.find('form');
  // console.log('Init', $$elems);
  $$elems.each((i,el) => {
    const placeholder = $(el).attr('placeholder');
    if (placeholder) {
      $('<span>' + placeholder + '</span>').insertAfter($(el));
    }
  });

  const $$contactByFields = $el.find('.form-elem_contact');

  $$contactByFields.each((i, el) => {
    generateContactList($(el));
  }); 
}

function generateContactList($el) {
  const options = $el.data('options').split(';');
  // console.log(options);

  if (!options.length) {
    // console.log('no options');
    return;
  }

  $el.addClass('js-select-input');

  const ul = $('<ul />');
  options.forEach((v, k) => {
    ul.append(`<li data-value="${v}"><span>${v}</span></li>`);
  });

  $el.find('input').parent().append(ul);

}

$( '.wpcf7' ).each(function() {
  const $self = $(this);
  $(this).find('[type="submit"]').click(function() {
    const $$errorTips = $self.find('.form-elem__error');
    $$errorTips.each(function() {
      $(this).remove();
    });
  });
});


$( '.wpcf7' ).on('wpcf7mailsent', function(evt) {
  const $msg = $(this).find('.wpcf7-mail-sent-ok'); 

  if ($msg.length) {
    setTimeout(() => {
      openFormPopUp($msg.text());
    }, 0);
        
  }
});

$( '.wpcf7' ).on('wpcf7mailfailed', function(evt) {
  const $msg = $(this).find('.wpcf7-mail-sent-ng'); 

  if ($msg.length) {
    setTimeout(() => {
      openFormPopUp($msg.text());
    }, 0);
        
  }
});

$( '.wpcf7' ).on('wpcf7invalid', function(evt) {
  const $tips = $(this).find('.wpcf7-not-valid-tip'); 
  $tips.each(function() {
    const target = $(this).closest('.form-elem');
    target.append('<span class="form-elem__error">' + $(this).text() + '</span>');

    if (target.closest('.form_contacts').length) {
      target.find('input,textarea').css('color', 'transparent');
      var placeholder = target.find('input+span, textarea+span');
      placeholder.hide();
      target.find('input,textarea').focus(function() {
        $(this).removeAttr('style');
        target.find('.form-elem__error').remove();
        placeholder.show();
      });
    }
  });
});







function openFormPopUp(msg) {
  const $popUp = $('.js-form-popup');
  $popUp.find('.js-form-popup-msg').text(msg);
  $popUp.addClass('is-open');
  $('.js-bg').addClass('is-open');
}


function initFormPopUp() {
  $('.js-form-popup').each(function() {
    const $self = $(this);
    const bg = $('.js-bg');
    $(this).find('.js-form-popup-close').click(function() {
      $self.removeClass('is-open');
      bg.removeClass('is-open');
    });
    bg.on('click', function() {
      $self.removeClass('is-open');
      bg.removeClass('is-open');
    });
  });
}



adjustCardTextHeight();
adjustRateTitleHeight();

range();


function checkContactForm($form) {
  // console.log('location', window.location.href);
  if (window.location.href.indexOf('wpcf7') === -1) {
    return;
  }

  const $targetForm = $form.find('form');

  if ($targetForm.hasClass('sent')) {
    const $msg = $form.find('.wpcf7-mail-sent-ok'); 

    if ($msg.length) {
      setTimeout(() => {
        openFormPopUp($msg.text());
      }, 0);
          
    }
  }

  if ($targetForm.hasClass('invalid')) {
    const $tips = $form.find('.wpcf7-not-valid-tip'); 
    $tips.each(function() {
      const target = $(this).closest('.form-elem');
      target.append('<span class="form-elem__error">' + $(this).text() + '</span>');

      if (target.closest('.form_contacts').length) {
        target.find('input,textarea').css('color', 'transparent');
        var placeholder = target.find('input+span, textarea+span');
        placeholder.hide();
        target.find('input,textarea').focus(function() {
          $(this).removeAttr('style');
          target.find('.form-elem__error').remove();
          placeholder.show();
        });
      }
    });
  }


  if ($targetForm.hasClass('failed')) {
    const $msg = $form.find('.wpcf7-mail-sent-ng'); 



    if ($msg.length) {
      setTimeout(() => {
        openFormPopUp($msg.text());
      }, 0);
          
    }
  }

  $targetForm.find('.form-elem').each(function() {
    const $input = $(this).find('input, textarea');
    if ($input.val()) {
      $(this).addClass('input-not-empty');
    }
  });
}
