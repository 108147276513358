import 'jquery-datetimepicker';


export default function formDate() { 
  const dateInput = $('.js-form-elem-date');
  dateInput.keydown(function(evt) {
  	evt.preventDefault();
  });
  jQuery.datetimepicker.setLocale('ru');

  const date = new Date();
  const month = date.getMonth();
  const day = date.getDate();

  // console.log(`+${date.getFullYear()}/${(month > 9 ? '' : '0') + month}/${(day > 9 ? '' : '0') + day}`);
  dateInput.datetimepicker({
  	timepicker:false,
 	format:'d/m/Y',
 	minDate: 0,
 	autoSize: true
  });
}
formDate();
