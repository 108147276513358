import throttle from 'lodash.throttle';

function initArticleCircles() {
  const $circleContainer = $('.js-article-circles-container');

  if (!$circleContainer.length) {
  	return;
  }

  const circleClasses = {
  	fill: ['is-filled', 'is-empty'],
  	size: ['is-xs', 'is-s', 'is-m', 'is-l'],
  	animation: ['is-tbl', 'is-tbr', 'is-btl', 'is-btr']
  };

 
  const $parent = $circleContainer.closest('.js-article-inner');
 	
  appendCircles();	

  $(window).resize(throttle(() => {
    $circleContainer.empty();
    appendCircles();
  }, 100));

  function appendCircles() {  	 	
  	const $circles = $('.js-article-circles');
  	let totalCircleHeight = 0;
  	const parentHeight =  $parent.outerHeight();

  	$circles.each(function() {
  		totalCircleHeight += $(this).outerHeight();
  	});

  	if (!totalCircleHeight || parentHeight > totalCircleHeight + $circles.first().outerHeight()) {
  		
  		const num = !totalCircleHeight ? 5 : getRandomArbitrary(0, 6);


  		let htmlString = '';

  		for (let i = 0; i < num; i++) {
  			const top = !totalCircleHeight ? getRandomArbitrary(0, 51) : getRandomArbitrary(0, 101);
  			const left = getRandomArbitrary(0, 101);
  			let circleClassNames = '';
  			let size = '';
  			let duration = 0;
  			for (let key in circleClasses) {
  				const props = circleClasses[key];
  				const index = parseInt(getRandomArbitrary(0, props.length));
  				circleClassNames += props[index];
  				circleClassNames += ' ';
  				if (key === 'size') {
  					size = props[index];
  				}
  			}

  			switch (size) {
  				case 'is-xs':
  					duration = 15;
  					break;
  				case 'is-s':
  					duration = 30;
  					break;
  				case 'is-m':
  					duration = 45;
  					break;
  				case 'is-l':
  					duration = 60;
  					break;
  				default:
  					duration = 15;
  			}

  			htmlString += `<div class="article-circle ${circleClassNames}" style="top: ${top}%; left: ${left}%; animation-duration: ${duration}s;"></div>`;
  		}


  		$circleContainer.append(`
			<div class="article__circles js-article-circles">
				${htmlString}
			</div>
  			`);
  		setTimeout(appendCircles, 0);

  	}

  }
}




function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export default initArticleCircles;
