export default function searchToggler() {
//   if()
  let search = $('.js-search');
  let btnSearch = search.find('.js-btn-search');
  let btnClose = search.find('.js-search-close');
  let searchBox = search.find('.js-search-box');
  let tabs = $('.js-tabs');
  if(search.length) {
    btnSearch.on('click', () => {
      searchBox.addClass('is-open');
      search.addClass('is-open');
      // tabs.addClass('is-closed');
    });
    btnClose.on('click', () => {
      searchBox.removeClass('is-open');
      search.removeClass('is-open');
      // tabs.removeClass('is-closed');
    });
  }
//   $('body').on('click', (e)=>{
//       if($(e.target).hasClass())
//   })
}
searchToggler();
