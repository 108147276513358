import IMask from 'imask';
export default function inputMask() {
  // console.log('tels', $('input[type="tel"]'));

  let tel = {
    mask: '+ 0 000 000 00 00'
  };
  let date = {
    mask: '00.00.0000'
  };
  
  $('input[type="tel"], input[name="billing_phone"]').each(function(i, el) {
    let maskType = 'tel';
    if (maskType === 'tel') {
      let mask = new IMask(el, tel);
    } else if (maskType === 'date') {
      let mask = new IMask(el, date);
    }
  });
}
