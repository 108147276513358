import noUiSlider from 'nouislider';
import wNumb from 'wnumb';
export default function range() {

  const marginSlider = $('.js-range');
  const setMinValue = +$('.js-min-price').val();
  const setMaxValue = +$('.js-max-price').val();
  const maxValue = +$('.js-max-price').data('initial-value');
  const minValue = +$('.js-min-price').data('initial-value');

  $('.filters__prices input').keydown((evt) => {
    evt.preventDefault();
  });

  if(marginSlider.length) {
    noUiSlider.create(marginSlider[0], {
      start: [ setMinValue, setMaxValue ],
      margin: 0,
      connect: true,
      range: {
        'min': minValue,
        'max': maxValue
      },
      format: wNumb({
        decimals: 0,
        thousand: '.',
        suffix: ' Р'
      }),
    });

    marginSlider[0].noUiSlider.on('update', function( values, handle ) {
      if ( handle ) {
        // console.log('HANDLE', handle);
        $('.js-max-price').val(values[handle]);
      } else {
        $('.js-min-price').val(values[handle]);
      }
    });
  }
  
}

