
// import '@babel/polyfill';
// import YouTubePlayer from 'youtube-player';
export default function stream() {
  // $('.js-player').each(function(index,el) {
  //   const id = $(el).data('id');
  //   const player = YouTubePlayer(el, {
  //     videoId: id,
  //     playerVars: {rel: 0, showinfo: 0}
  //   });
    
    
  //   const parent = $(this).parents('.js-video');
  //   const btn = parent.find('.js-play');
    
  //   btn.on('click',() => {
  //     player.playVideo();
  //     parent.addClass('is-playing');
  //   });
  //   player.on('stateChange', (evt) => {
  //     if (evt.data === 2) {
  //       parent.removeClass('is-playing');
  //       player.pauseVideo();
  //     } else if (evt.data === 1) {
  //       parent.addClass('is-playing');
  //     }
  //   });
   
  // });

}


